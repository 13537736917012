<template>
  <div>
      <div class="carousel-form">
          <el-button type="primary"  @click="addTheme">新增</el-button>
      </div>
      <div class="carousel-table">
            <el-table
              :data="lsit"
              border
              style="width: 100%">
              <el-table-column
                type="index">
              </el-table-column>
              <el-table-column
                prop="img"
                label="图片"
                width="200">
                <template slot-scope="scope">
                  <img :src="scope.row.img" alt="" style="width:150px">
                </template>
              </el-table-column>
              <el-table-column
                prop="title"
                label="标题"
                width="200">
              </el-table-column>
              <el-table-column
                prop="content"
                label="内容描述"
                width="330">
                <template slot-scope="scope">
                  <span style="white-space:normal;">{{scope.row.content}}</span>
                </template>
              </el-table-column>

              <el-table-column
                label="操作"
                width="230">
                <template slot-scope="scope">
                  <el-button @click="edit(scope.row)" type="text" size="small">替换</el-button>
                  <el-button @click="deleteTheme(scope.row)" type="text" size="small">删除</el-button>
                  <el-button @click="relation(scope.row)"  type="text" size="small">关联文章管理</el-button>
                </template>
              </el-table-column>
            </el-table>
      </div>
  </div>
  
</template>

<script>
import {QUERY,UPDATE,DELETE, QUERYED}  from '@/services/dao.js';
export default {    
    data(){
        return {
            lsit: [{
                id: 2,
                img: 'https://car2.autoimg.cn/cardfs/product/g27/M04/CE/0A/1024x0_1_q95_autohomecar__ChsEfF1cCLqAf3FFAArPJeOpUBI092.jpg',
                title:"fdsfsdfdsfds",
                content:1,
                createTime:"1981-01-01 22:22:22"
          }]
        }
    },
    created(){
      this.init();
    },
    methods: {
       async init(){
            let info=  await  QUERYED("post","",'  TtTheme { img title id content }');
            this.lsit.splice(0,this.lsit.length);
            info.data.TtTheme.forEach(element => {
               this.lsit.push({
                    title:element.title,
                    content:element.content,
                    img:this.api.LoginURL.concat(element.img),
                    id:element.id,
                });
            });
        },
        edit(row){
          this.$router.push({
            path: "/editTheme",
            query:{
              id:row.id,
              title: row.title,
              img: row.img,
              content: row.content
            }
          });
          
        },
        relation(row){
          this.$router.push({path: "/themeArticleManage",query:{id:row.id}});
        },
      async  deleteTheme(row){
           let info=  await   DELETE("post","",'  delete_TtTheme(where: {id: {_eq: '+row.id+'}}) {affected_rows}');// info articleTitle
           if(info.errors){
              //alert("删除失败,请检查是该主题否有关联文章!");
              this.$message({
                message: '删除失败,请检查是该主题否有关联文章!',
                type: 'warning'
              });
               return;
           }  
           if(!info.error&&info.data.delete_TtTheme.affected_rows>0){
            //alert("删除成功");
             this.$message({
                message: '删除成功',
                type: 'success'
              });
              this.init();
           }
        },
        addTheme(){
            this.$router.push("/addTheme");
        }
    }
}
</script>

<style scoped>
 .carousel-table{
     margin-top: 20px;
 }
</style>